@import "./leaflet.less";
@import "./leaflet-fullscreen.less";
@import "./leaflet.draw.less";
@import "./L.Control.Sidebar.less";
@import "./L.Control.SidebarToggler.less";
@import "./L.Control.Locate.less";
@import "./l.geosearch.less";
@import "./nvd3.less";

.leaflet-container .leaflet-control-mouseposition {
    padding: 0 5px;
    margin:0;
}

.leaflet-sidebar {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    position: absolute;
    z-index: 1001;
    .leaflet-control {
        box-shadow: none;
        border-radius: 0;
    }
    .close {
        top: 10px;
        right: 15px;
        z-index: 999;
    }
}


@media (min-width: 768px) and (max-width: 1199px) {
    .leaflet-sidebar {
        width: 390px;
    }
    .leaflet-sidebar.left.visible ~ .leaflet-left {
        left: 390px;
    }
    .leaflet-sidebar.right.visible ~ .leaflet-right {
        right: 390px;
    }
}

.history-back-button {
    color: gray !important;
}

@media print {
    html {
        padding: 0px !important;
    }

    .leaflet-sidebar,
    .leaflet-top.leaflet-left,
    .leaflet-top.leaflet-right,
    .leaflet-control-easyPrint-button {
        display: none !important;
    }

    #map {
        width: 800px;
    }

    .extend-on-print { width: 100% !important; }
    .leaflet-top.leaflet-left,
    .leaflet-top.leaflet-right {
        display: none;
        visibility: hidden;
    }
}

.leaflet-draw-section {
    border-radius: 4px !important;
    background: none !important;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
}
